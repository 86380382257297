.cards {
  padding: 0 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.cards .card {
  border: 1px solid #CFD2D6;
  border-radius: 5px;
  padding: 10%;
  background-color: black;
  position: relative;
}

.cards .card p  {
	margin: 15px 0 0 0;
}

.cards .card p .primary  {
	font-weight: 400;
	font-size: 21px;
	line-height: 28px;
	color: #000000;
}

.cards .next {
  flex-shrink: 0;
}

.cards .card .column {
display: flex;
flex-direction: column;
}

.cards .card .row {
display: flex;
justify-content: space-between;
align-items: center;
}

.cards .card .title {
display: flex;
font-family: 'Cutive Mono';
color: #f7f7f7;
margin-bottom: 25px;
}

.cards .card .title > span:nth-of-type(1) {
  font-family: 'Keania One';
  font-size: 74px;
  line-height: 74px;
}

.cards .card .title > span:nth-of-type(2) {
  margin-top: 12px;
  margin-left: 10px;
display: inline-flex;
flex-direction: column;
}

.cards .card .title > span:nth-of-type(2) > span:nth-of-type(1) {
  font-size: 28px;
  line-height: 28px;
}

.cards .card .title > span:nth-of-type(2) > span:nth-of-type(2) {
  font-size: 24px;
  line-height: 24px;
  margin-top: 2px;
  margin-left: 2px;
}

.cards .card .title > span:nth-of-type(2) > span:nth-of-type(1) > span {
color: #ebd50d;
}

.cards .card .description {
display: flex;
font-family: 'Cutive Mono';
flex-direction: column;
align-items: flex-end;
justify-content: center;
margin-right: 4px;
margin-left: 12px;
}

.cards .card .description > span:nth-of-type(1) {
color: #FAFAFA;
font-size: 23px;
    line-height: 23px;
}

.cards .card .description > span:nth-of-type(2) {
  color: #8c8c8c;
  text-decoration: line-through;
  font-size: 18px;
  line-height: 18px;
  margin-top: 6px;
  margin-right: 2px;
}

.cards .card input[type=button] {
width: 109px;
margin: 0;
align-self: center;
margin-top: 2px;
}

.cards .card .logo {
align-self: flex-start;
  width: 16%;
margin: -6% -6% 0 0;
}

@media screen and (max-width: 1240px) and (min-width: 1121px) {

  .cards .card .logo {
    width: 15%;
  }

  .cards .card .title {
    margin-bottom: 15px;
  }

  .cards .card .description > span:nth-of-type(1) {
    font-size: 20px;
    line-height: 20px;
  }

  .cards .card .description > span:nth-of-type(2) {
    font-size: 17px;
    line-height: 17px;
  }

  .cards .card .title > span:nth-of-type(1) {
    font-size: 70px;
    line-height: 70px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(1) {
    font-size: 24px;
    line-height: 24px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(2) {
    font-size: 20px;
    line-height: 20px;
  }
  
}

@media screen and (max-width: 1120px) {
	.cards {
		grid-template-columns: repeat(2, 1fr);
	}
}


@media screen and (max-width: 875px) and (min-width: 794px) {

  .cards .card .logo {
    width: 15%;
  }

  .cards .card .title {
    margin-bottom: 15px;
  }

  .cards .card .description > span:nth-of-type(1) {
    font-size: 20px;
    line-height: 20px;
  }

  .cards .card .description > span:nth-of-type(2) {
    font-size: 17px;
    line-height: 17px;
  }

  .cards .card .title > span:nth-of-type(1) {
    font-size: 70px;
    line-height: 70px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(1) {
    font-size: 24px;
    line-height: 24px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(2) {
    font-size: 20px;
    line-height: 20px;
  }
  
}

@media screen and (max-width: 793px) {
	.cards {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (max-width: 508px) {

  .cards {
    padding: 0;
  }

  .cards .card .logo {
    width: 15%;
  }

  .cards .card .title {
    margin-bottom: 15px;
  }

  .cards .card .description > span:nth-of-type(1) {
    font-size: 20px;
    line-height: 20px;
  }

  .cards .card .description > span:nth-of-type(2) {
    font-size: 17px;
    line-height: 17px;
  }

  .cards .card .title > span:nth-of-type(1) {
    font-size: 70px;
    line-height: 70px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(1) {
    font-size: 24px;
    line-height: 24px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(2) {
    font-size: 20px;
    line-height: 20px;
  }
  
}


@media screen and (max-width: 340px) {
  .cards .card .title > span:nth-of-type(1) {
    font-size: 58px;
    line-height: 58px;
  }

  .cards .card .title > span:nth-of-type(2) {
    margin-top: 7px;
  }

}

@media screen and (max-width: 300px) {
  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(1) {
    font-size: 22px;
    line-height: 22px;
  }

  .cards .card .title > span:nth-of-type(2) > span:nth-of-type(2) {
    font-size: 18px;
    line-height: 18px;
  }

  .cards .card .logo {
    width: 12%;
  }
}