html {
  background: black;
  color: white;
  font-size: 30px;
}

a {
	cursor: pointer;
	color: #EBD50D;
	text-decoration: none;
}

h3 {
  font-family: "Saira Stencil One";
  letter-spacing: 0.07em;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  margin-block-start: 1.2em;
  margin-block-end: 1.2em;
  display: inline-flex;
}

em {
    font-weight: 900;
    padding: 0 4px;
    color: #000000;
    background-color: white;
	font-style: normal;
}

h3.not-found {
	align-self: center;
}

form {
	display: flex;
    flex-direction: column;
}

.alert {
  display: inline-flex;
	border: 1px solid;
	border-radius: 3px;
	padding: 13px;
  font-family: 'Cutive Mono';
	font-size: 17px;
	line-height: 24px;
	flex-direction: column;
	gap: 20px;
}

.alert p { 
	margin: 0
}

.alert a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 5px;
	text-decoration-thickness: 0.5px;
}

.alert.top {
	margin-top: 40px;
}

.alert.bottom {
	margin-bottom: 40px;
}

.alert.success {
  border-color: #1a6237;
  background: #090909;
  color: #48ff92;
}

.alert.info {
  border-color: #195764;
  background: #090909;
  color: #43deff;
}

.alert.failure {
  border-color: #6b1d1d;
  color: #ff5555;
  background: #090909;
}

.fade-in-section.is-visible h3 {
  animation: glitch 1s linear 1;
  animation-fill-mode: forwards;
}


@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

.fade-in-section.is-visible h3:before,
.fade-in-section.is-visible h3:after {
  content: attr(data-animation);
  position: absolute;
  left: 0;
}

.fade-in-section.is-visible h3:before {
  animation: glitchTop 1s linear 1;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

.fade-in-section.is-visible h3:after {
  animation: glitchBotom 1s linear 1;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}

button, input, textarea {
	margin: 0;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

input[type=button] {
  padding: 4px 12px;
  border: 1px solid #ead50e;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Cutive Mono';
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
  color: #ead50e;
  border-radius: 3px;
}

input[type=button]:active, 
input[type=submit]:active, 
input[type=button].browse:active ~ label.file {
  transform: scale(0.9);
}

input[type=button].browse:active {
  box-shadow: none;
}

input[type=button].browse:active ~ label.file {
  margin-top: -5px;
  margin-bottom: 5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dialog .content,
.main.content {
	font-family: 'Cutive Mono';
	font-size: 19px;
	line-height: 24px;
}

.main.content {
	padding: 0 8%;
	padding-top: 100px;
	padding-bottom: 200px;
}

.main.content .fade-in-section:first-child .box {
	margin-top: 0;
}

.main.content .flex {
	display: flex;
	gap: 10%;
}

.main.content .flex .item {
	flex-basis: 50%;
}

.main.content .flex.center {
	justify-content: center;
	gap: 5%
}

.fade-in-section {
  opacity: 0;
  transition: opacity 2s ease-out;
}
.fade-in-section.is-visible {
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.box {
  width: 50%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-basis: 100%;
}

.box .content {
	padding: 26px 4%;
	margin: 0;
	font-size: 24px;
	line-height: 32px;
	width: fit-content;
}

.box .content > :first-child {
	margin-top: 0;
}

.box .content > :last-child {
	margin-bottom: 0;
}

.fade-in-section:nth-child(even) .box {
  padding-right: 50%;
  text-align: right;
  flex-direction: row-reverse;
}

.fade-in-section:nth-child(odd) .box {
  padding-left: 50%;
  text-align: left;
  flex-direction: row;
}

.fade-in-section .box span.indicator {
	background-color: transparent;
}

.fade-in-section .box span.indicator {
	background-image: linear-gradient(#EBD50D, #EBD50D);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	transition: background-size 2s ease-out;
  width: 3px;
	background-size: 100% 0%;
  flex-shrink: 0;
}

.fade-in-section.is-visible .box span.indicator {
	background-size: 100% 100%;
}

section .fade-in-section:first-child .box span.indicator {
	display: inline-flex;
}

form label {
  font-family: 'Cutive Mono';
  font-size: 19px;
}

input:not([type=checkbox]):not([type=radio]) {
	width: 100%;
	padding: 13px;
	margin-top: 4px;
  font-family: 'Cutive Mono';
  font-size: 19px;
	line-height: 24px;
	border: 1px solid #525252;
	border-radius: 3px;
	box-sizing: border-box;
	background-color: #0E0E0E;
	color: #fafafa;
}

input.button {
	padding: 13px;
  font-family: 'Cutive Mono';
	font-size: 19px;
	line-height: 24px;
	border: 1px solid transparent;
	background-color: transparent;
	color: transparent;
	border-radius: 3px;
	margin: 40px 0px;
	cursor: pointer;
  font-weight: 900;
}

input[type=submit].button, input[type=button].button {
  margin: 40px 0px;
  transition: all 0.2s ease-in-out;
}

/* submit buttons cause some permanent changes on the server (f.i. sign up or checkout) */
input[type=submit].button {
	background-color: #EBD50D;
	border-color: #EBD50D;
	color: #0E0E0E;
  text-transform: lowercase;
}

input[type=submit].button.outlined,
input[type=button].button.outlined {
	background-color: transparent;
}

/* submit outlined buttons cause some temporary changes on the server (f.i. sign in) */
input[type=submit].button.outlined  {
	border-color: #EBD50D;
	color: #EBD50D;
}

/* regular outlined buttons don't cause any changes on the server side (f.i. cancel) */
input[type=button].button.outlined {
	border-color: #FAFAFA;
	color: #FAFAFA;
}

.controls {
	display: flex;
	gap: 20px;
	margin: 40px 0;
    flex-wrap: wrap-reverse;
}

.controls input.button {
	margin: 0;
    padding-left: 30px;
    padding-right: 30px;
	flex-basis: 0%;
    flex-grow: 1;
}

input[type=file] {
	display: none;
}

.input fieldset {
    border-color: #525252;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
	padding: 21px 17px;
	margin: 0;
  flex-basis: 100%;
}

.input.checkbox {
	display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
}

.input.checkbox input {
	display: none;
}

.input.checkbox svg {
  flex-shrink: 0;
  margin-top: 3px;
}

.input ul.horizontal {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 0;
    margin: 0;
    font-size: 19px;
    line-height: 24px;
}

.input ul.horizontal li {
	display: flex;
	flex: 0 0 auto;
	height: 35px;
	justify-content: center;
	align-items: center;
	border: 1px solid #525252;
	border-radius: 3px;
	padding: 0 12px;
	margin: 5px 5px;
	cursor: pointer;
	color: #FAFAFA;
}

.input ul li:focus {
	outline: none;
    box-shadow: 0 0 3px 1px #EBD50D;
    border-color: #EBD50D;
}

.input ul li.selected:focus {
	border-color: #EBD50D;
}

.input ul li.selected {
	border-color: #FAFAFA;
	background-color: #FAFAFA;
	color: #0E0E0E;
}

.input ul li input[type=checkbox],
.input ul li input[type=radio] {
	padding: 0;
    margin: 0;
    position: absolute;
    z-index: -1;
    width: 0;
    opacity: 0;
}

.input ul li.editable {
	height: 37px;
	padding: 0;
	border: none;
}

.input ul li.editable input[type=text] {
	height: 100%;
	padding: 0 12px;
    margin: 0;
	border-radius: 3px;
}

.input ul li.editable input[type=text].dtc {
	width: 88px;
}

.input ul li.editable:focus {
    box-shadow: none;
}

.input ul li label {
	cursor: inherit;
}

.input ul li.checkbox label,
.input ul li.radio label {
	padding-right: 3px;
}

textarea {
	width: 100%;
	padding: 13px;
	margin-top: 40px;
	font-size: 19px;
	line-height: 24px;
	border: 1px solid #525252;
	border-radius: 3px;
	box-sizing: border-box;
	resize: vertical;
	background-color: #0E0E0E;
	color: #fafafa;
  font-family: 'Cutive Mono';
  font-weight: 900;
}

textarea::placeholder {
	color: #525252;
	opacity: 1;
}

div.input input[type=text], div.input input[type=email] {
  font-family: 'Cutive Mono';
  font-weight: 900;
}

div.input label.file {
	border: 1px solid #525252;
  box-sizing: border-box;
  color: #FAFAFA;
  border-radius: 0 0 3px 3px;
  font-family: 'Cutive Mono';
  font-size: 19px;
  display: block;
	border-top: none;
	padding: 13px;
  transition: all 0.2s ease-in-out;
}

div.input .file-browser-container {
  width: 100%;
}

div.input label.file .placeholder {
	color: #525252;
}

div.input input.browse {
	border-radius: 3px 3px 0 0;
	background-color: #FAFAFA;
	border-color: #525252;
	color: #0E0E0E;
	cursor: pointer;
	margin: 4px 0 0 0;
}

div.input > label,
div.input > label p,
div.input > label ul,
div.input fieldset > label,
div.input fieldset legend {
	font-family: 'Cutive Mono';
	font-size: 17px;
	line-height: 24px;
	color: #FAFAFA;
}

div.input > label p {
	margin: 0;
}

div.input > label ul {
	padding-left: 20px;
}

div.input fieldset > label {
    padding: 25px 0 20px 0;
    display: inline-block;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
	outline: none !important;
	box-shadow: 0 0 3px 1px #EBD50D !important;
	border-color: #EBD50D !important;
}

a:focus {
	outline: none;
}

::-moz-focus-inner { 
	border: 0;
}

div.input {
	margin-top: 17px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

div.input a {
  font-family: 'Cutive Mono';
	line-height: 24px;
	font-size: 17px;
}

.overlay {
	display: block;
	position: fixed;
	width: 100%;
    height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 3;
	left: 0;
	top: 0;
}

.overlay.dialog {
	display: flex;
  overflow-y: auto;
}

.overlay.dialog .inner {
	width: 100%;
	height: 100%;
	cursor: pointer;
	position: absolute;
}

.overlay.dialog .wrapper {
  display: flex;
  flex-direction: column;
  background: #0E0E0E;
  border: 1px solid #525252;
  padding: 4% 10%;
}

.overlay.dialog .content {
  display: flex;
  flex-direction: column;
  padding: 4% 0;
  flex-basis: 700px;
  max-width: 78%;
  z-index: 1;
  margin: auto;
}

.overlay.dialog .content ul {
	padding-left: 26px;
}

.overlay.dialog .content h3 {
  margin-block-start: 1em;
  margin-block-end: 1.2em;
}


/* Variables
================================== */
/* Tables
================================== */

.Rtable {
	display: flex;
	flex-direction: column;
}

.Rtable-raw {
	display: flex;
	flex-wrap: nowrap;
}

.Rtable-cell {
	box-sizing: border-box;
	flex-basis: 100%;
	padding: 0.8em 0.8em;
	overflow: hidden;
	list-style: none;
	border: solid 1px;
}

.Rtable-raw .Rtable-cell--right {
	justify-content: flex-end;
}

.Rtable-raw .Rtable-cell--foot {
	justify-content: center;
}

.Rtable-raw.first-as-head:first-child {
	font-size: 17px;
	font-weight: 600;
}

/* Table column sizing
================================== */
.Rtable-cell--50 {
	flex-basis: 50%;
}

.Rtable-cell--40 {
	flex-basis: 40%;
}

.Rtable-cell--60 {
	flex-basis: 60%;
}

.Rtable-cell--30 {
	flex-basis: 30%;
}

.Rtable-cell--20 {
	flex-basis: 20%;
}

.Rtable-cell--25 {
	flex-basis: 25%;
}

.Rtable-cell--15 {
	flex-basis: 15%;
}

.Rtable-cell--10 {
	flex-basis: 10%;
}

/* Apply styles
================================== */

.Rtable-cell {
	display: flex;
	align-items: center;
}

.Rtable-raw .Rtable-cell {
	border-color: #0E0E0E;
	background-color: #0E0E0E;
}

.Rtable-raw .Rtable-cell--foot {
	background-color: #222222;
    color: white;
}

.Rtable-raw:nth-child(odd) .Rtable-cell {
	background-color: #0A0909;
}

.Rtable-raw:nth-child(odd) .Rtable-cell--foot {
	background-color: #333333;
}

.Rtable-raw:hover .Rtable-cell {
	background-color: #000000;
}

.Rtable-raw:hover .Rtable-cell--foot {
	background-color: #1f1f1f;
}

.Rtable-cell.attention {
	color: #ff5555;
}

.Rtable-cell.success {
	color: #48ff92;
}

.Rtable-cell.info {
	color: #43deff;
}

.main.content .item.sticky > div {
	position: sticky;
	top: 20px;
	flex-basis: 100%;
}

.main.content .item.sticky .box {
	margin: 0;
	padding: 0;
	width: 100%;
}

@media screen and (max-width: 768px) {
    .main.content .flex.reverse {
        flex-direction: column-reverse;
		gap: 30px;
    }

	.main.content .item.sticky > div {
		position: initial;
		top: initial;
	}

	.main.content .item.sticky .working-hours-row {
		flex-direction: row;
	}

	.main.content .item.sticky .box {
		align-items: center;
	}

	.main.content .item.sticky {
		position: sticky;
		top: 0;
		background: #000000e6;
		padding: 20px 0;
	}
}

@media screen and (max-width: 380px) {
	.main.content .item.sticky .working-hours-row {
		flex-direction: column;
	}
}

@media screen and (max-width: 1024px) {
	.main.content .flex .item {
		flex-basis: 80%;
	}

	section .fade-in-section:first-child .box {
		margin-top: 100px;
	}

	.fade-in-section:nth-child(odd) .box,
	.fade-in-section:nth-child(even) .box {
		flex-direction: column-reverse;
		text-align: center;
		width: 100%;
		align-items: center;
		gap: 100px;
		margin-top: 0;
		margin-bottom: 100px;
		padding: 0;
	}

	.fade-in-section.is-visible .box span.indicator {
		height: 120px;
	}

	section .fade-in-section:last-child .box span.indicator {
		display: none;
	}
}

@media all and (max-width: 960px) {
	.Rtable {
		display: block;
	}

	.Rtable-raw .Rtable-cell--right {
		justify-content: flex-start;
	}

	.Rtable .Rtable-raw {
		margin-bottom: 1em;
		flex-direction: column;
	}

	.Rtable-raw .Rtable-cell {
		width: 100% !important;
		background-color: #0A0909;
	}
	
	.Rtable-raw .Rtable-cell--foot {
		background-color: #222222;
		justify-content: center;
	}
}


@media screen and (max-width: 768px) {
	.box .content {
		padding: 0;
	}

	.main.content .flex .item {
		flex-basis: 100%;
	}

	.overlay.dialog .content {
		max-width: 96%;
	}

	.overlay.dialog .wrapper {
		padding: 4% 6%;
	}
}

@media screen and (max-width: 380px) {
	.controls input.button {
		white-space: normal;
		flex-basis: 100%;
	}
}
