.working-hours-box {
    display: flex;
    flex-direction: column;
}

.working-hours-row {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
}

.working-hours-box .small-up {
    font-size: 14px;
    vertical-align: text-top;
    line-height: 14px;
    margin-left: -6px;
    text-transform: uppercase;
}

@media screen and (max-width: 1400px) {
    .working-hours-box {
        display: flex;
        gap: 40px;
    }
    
    .working-hours-row {
        flex-direction: column;
        gap: 20px;
    }

    .working-hours-row div:last-child {
        font-size: 22px;
    }
}