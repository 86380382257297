.App-logo {
  height: 100%;
  transform: scale(0.9);
  animation: scaleUp 1.5s;
  animation-fill-mode: forwards;
  height: 28vmin;
}

.App-logo:active {
	animation: scaleDown 0.2s;
}

@keyframes scaleUp {
  100% {
    transform: scale(1.0); 
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1.0); 
  }
  100% {
    transform: scale(0.9); 
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8% 8% 0 8%;
  height: 28vmin;
}

.App-header .navigation-box {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  gap: 50px;
}

.App-header .navigation-box input[type=button] {
  margin: 0;
}

.App-header nav {
  height: fit-content;
  display: flex;
}

.App-header nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 50px;
}

.App-header nav ul li {
  list-style: none;
  font-family: 'Cutive Mono';
  font-size: 20px;
  line-height: 30px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  position: relative;
}

.App-header nav ul li a {
  z-index: 3;
  display: inline-flex;
  color: white;
	flex-direction: column;
}

.App-header nav ul li a span {
	height: 2px;
  background-color: transparent;
}

.App-header nav ul li a p {
  margin: 0;
  flex-shrink: 1;
  white-space: nowrap;
  display: inline-flex;
  align-self: center;
}

.App-header nav ul li a span.indicator {
	background-image: linear-gradient(white, white);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	transition: background-size .4s;
}

.App-header nav ul li a:hover span.indicator,
.App-header nav ul li a.active span.indicator,
.App-header nav ul li a:focus span.indicator,
.App-header nav ul li:focus-within > a span.indicator,
.App-header nav ul li:hover > a span.indicator {
	background-size: 100% 100%;
}

.App-header  nav.submenu {
	z-index: 2;
	visibility: hidden;
  display: flex;
  height: auto;
  position: absolute;
  padding-top: 48px;
}

.App-header nav.submenu:hover {
	visibility: visible;
}

.App-header nav.submenu ul {
	flex-direction: column;
	flex-grow: 1;
  gap: 10px;
}

.App-header  nav.submenu ul li {
  font-size: 16px;
  line-height: 28px;
}

.App-header nav ul li a:hover ~ nav.submenu,
.App-header nav ul li:focus-within nav.submenu {
	visibility: visible;
}

.App-header nav.submenu ul li a {
  color: white;
}

.App-header nav.submenu ul li a p {
  background-color: #313131;
	justify-content: center;
  flex-basis: 100%;
  padding: 0 8px;
  border: 1px solid transparent;
  transition : border .4s ease-in-out;
}

.App-header nav.submenu ul li:nth-child(odd) a p {
  transform: skewY(-1deg);
}

.App-header nav.submenu ul li:nth-child(even) a p {
  transform: skewY(1deg);
}

.App-header nav.submenu ul li:nth-child(odd) a span.indicator  {
  transform: skewY(-1deg);
}

.App-header nav.submenu ul li:nth-child(even) a span.indicator {
  transform: skewY(1deg);
}

.App-header nav.submenu ul li a span.indicator {
  display: none;
}


.App-header nav.submenu ul li a:hover p,
.App-header nav.submenu ul li a.active p,
.App-header nav.submenu ul li a:focus p,
.App-header nav.submenu ul li:focus-within > a p,
.App-header nav.submenu ul li:hover > a p {
	border: 1px solid white;
}



.overlay.menu {
	display: none;
}

.App-header .menuIcon {
	display: none;
	width: auto;
  min-width: auto;
  height: 70px;
  position: absolute;
  right: 7px;
  margin-bottom: 0;
}


@media screen and (max-width: 1024px) {
  .App-header nav ul {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
	.overlay.menu {
		display: block;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: black;
		z-index: 3;
		opacity: 0.9;
		left: 0;
		top: 0;
	}

	nav {
		max-height: 100vh;
		overflow-y: scroll;
	}

	.App-header .menuIcon {
		display: flex;
		position: fixed;
		bottom: 25%;
		color: #000000;
		transform: rotate(-90deg);
		height: 43px;
		background: rgb(255, 255, 255);
		cursor: pointer;
		font-family: "Cutive Mono";
    font-size: 16px;
    line-height: 35px;
    font-weight: 600;
		list-style: none;
		padding: 0 33px;
		padding-bottom: 8px;
		align-items: flex-start;
		border-radius: 3px 3px 0 0;
		z-index: 2;
		right: -43px;
		transition: right 0.4s ease-out;
		text-align: center;
	}

  .App-header .menuIcon:hover, .App-header .menuIcon:active {
    right: -35px;
  }

	.App-header nav {
		display: none;
		height: auto;
		z-index: 4;
	}

  .App-header .navigation-box {
    height: auto;
    padding-bottom: 2%;
  }

	.App-header .navigation-box > nav {
    bottom: 10%;
    right: 30px;
    position: fixed;
    width: auto;
    padding: 20px;
	}

	.App-header nav ul {
		flex-direction: column;
    gap: 20px;
	}

	.App-header nav ul li {
		flex-direction: column;
	}

	.App-header  nav ul li a p {
    justify-content: center;
	}

	.App-header nav.submenu {
		display: inline-flex;
    visibility: visible;
    position: relative;
    margin-top: 20px;
    padding: 0;
    overflow-y: visible;
	}

  .App-header nav.submenu ul {
    gap: 20px;
  }

	.App-header nav.submenu ul li a {
    padding: 0;
	}

	.App-header  nav.submenu ul li a p {
    	justify-content: center;
	}
}